import { createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown w-250px w-md-300px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "px-7 py-5" }
const _hoisted_3 = { class: "mb-10" }
const _hoisted_4 = { class: "mb-10" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "form-check form-check-sm form-check-custom form-check-solid me-5" }
const _hoisted_7 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_8 = { class: "mb-10" }
const _hoisted_9 = { class: "form-check form-switch form-switch-sm form-check-custom form-check-solid" }
const _hoisted_10 = { class: "form-check-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "px-7 py-5" }, [
      _createElementVNode("div", { class: "fs-5 text-dark fw-bolder" }, "Filter Options")
    ], -1)),
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "separator border-gray-200" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label fw-bold" }, "Status:", -1)),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select form-select-solid select2-hidden-accessible",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.status) = $event))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("option", {
              label: "Approved",
              value: "1"
            }, "Approved", -1),
            _createElementVNode("option", {
              label: "Pending",
              value: "2"
            }, "Pending", -1),
            _createElementVNode("option", {
              label: "In Process",
              value: "3"
            }, "In Process", -1),
            _createElementVNode("option", {
              label: "Rejected",
              value: "4"
            }, "Rejected", -1)
          ]), 512), [
            [_vModelSelect, _ctx.data.status]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label fw-bold" }, "Member Type:", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              value: "1",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.author) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.data.author]
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "form-check-label user-select-none" }, " Author ", -1))
          ]),
          _createElementVNode("label", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              value: "2",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.customer) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.data.customer]
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "form-check-label user-select-none" }, " Customer ", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-label fw-bold" }, "Notifications:", -1)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              name: "notifications",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.notifications) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.data.notifications]
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "form-check-label user-select-none" }, "Enabled", -1))
          ])
        ])
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "d-flex justify-content-end" }, [
        _createElementVNode("button", {
          type: "reset",
          class: "btn btn-sm btn-white btn-active-light-primary me-2",
          "data-kt-menu-dismiss": "true"
        }, " Reset "),
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn-sm btn-primary",
          "data-kt-menu-dismiss": "true"
        }, " Apply ")
      ], -1))
    ])
  ]))
}