import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 py-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body p-0 d-flex flex-column" }
const _hoisted_6 = { class: "card-p pt-5 bg-white flex-grow-1" }
const _hoisted_7 = { class: "row g-0" }
const _hoisted_8 = { class: "col mr-8" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "svg-icon svg-icon-5 svg-icon-success ms-1" }
const _hoisted_11 = { class: "row g-0 mt-8" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "d-flex align-items-center" }
const _hoisted_14 = { class: "svg-icon svg-icon-5 svg-icon-danger ms-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Sales Overview"),
        _createElementVNode("span", { class: "text-muted fw-bold fs-7" }, "Recent sales statistics")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "Average Sale", -1)),
            _createElementVNode("div", _hoisted_9, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fs-4 fw-bolder" }, "$650", -1)),
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr066.svg" })
              ])
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col" }, [
            _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "Commission"),
            _createElementVNode("div", { class: "fs-4 fw-bolder" }, "$233,600")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col mr-8" }, [
            _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "Annual Taxes 2019"),
            _createElementVNode("div", { class: "fs-4 fw-bolder" }, "$29,004")
          ], -1)),
          _createElementVNode("div", _hoisted_12, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "Annual Income", -1)),
            _createElementVNode("div", _hoisted_13, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fs-4 fw-bolder" }, "$1,480,00", -1)),
              _createElementVNode("span", _hoisted_14, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr065.svg" })
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "mixed-widget-3-chart card-rounded-bottom",
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: _ctx.chartHeight,
        type: "area"
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}