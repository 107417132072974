import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 py-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body p-0 d-flex flex-column" }
const _hoisted_6 = { class: "card-px pt-5 pb-10 flex-grow-1" }
const _hoisted_7 = { class: "row g-0 mt-5 mb-10" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "d-flex align-items-center me-2" }
const _hoisted_10 = { class: "symbol symbol-50px me-3" }
const _hoisted_11 = { class: "symbol-label bg-light-info" }
const _hoisted_12 = { class: "svg-icon svg-icon-1 svg-icon-info" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "d-flex align-items-center me-2" }
const _hoisted_15 = { class: "symbol symbol-50px me-3" }
const _hoisted_16 = { class: "symbol-label bg-light-danger" }
const _hoisted_17 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_18 = { class: "row g-0" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "d-flex align-items-center me-2" }
const _hoisted_21 = { class: "symbol symbol-50px me-3" }
const _hoisted_22 = { class: "symbol-label bg-light-success" }
const _hoisted_23 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "d-flex align-items-center me-2" }
const _hoisted_26 = { class: "symbol symbol-50px me-3" }
const _hoisted_27 = { class: "symbol-label bg-light-primary" }
const _hoisted_28 = { class: "svg-icon svg-icon-1 svg-icon-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Sales Statistics"),
        _createElementVNode("span", { class: "text-muted fw-bold fs-7" }, "Recent sales statistics")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art007.svg" })
                  ])
                ])
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", null, [
                _createElementVNode("div", { class: "fs-4 text-dark fw-bolder" }, "$2,034"),
                _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "Author Sales")
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs027.svg" })
                  ])
                ])
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                _createElementVNode("div", { class: "fs-4 text-dark fw-bolder" }, "$706"),
                _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "Commision")
              ], -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("span", _hoisted_23, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/ecommerce/ecm002.svg" })
                  ])
                ])
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                _createElementVNode("div", { class: "fs-4 text-dark fw-bolder" }, "$49"),
                _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "Average Bid")
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("span", _hoisted_28, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/ecommerce/ecm010.svg" })
                  ])
                ])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("div", null, [
                _createElementVNode("div", { class: "fs-4 text-dark fw-bolder" }, "$5.8M"),
                _createElementVNode("div", { class: "fs-7 text-muted fw-bold" }, "All Time Sales")
              ], -1))
            ])
          ])
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "mixed-widget-6-chart card-rounded-bottom",
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: _ctx.chartHeight,
        type: "area"
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}