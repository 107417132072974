import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "d-flex align-items-center pe-2 mb-5" }
const _hoisted_4 = { class: "text-muted fw-bolder fs-5 flex-grow-1" }
const _hoisted_5 = { class: "symbol symbol-50px" }
const _hoisted_6 = { class: "symbol-label bg-light" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-4"
}
const _hoisted_9 = { class: "py-3" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.time), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createElementVNode("img", {
                src: _ctx.image,
                class: "h-50 align-self-center",
                alt: ""
              }, null, 8, _hoisted_7)
            ])
          ])
        ]),
        _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_9, [
          _createElementVNode("span", { innerHTML: _ctx.description }, null, 8, _hoisted_10)
        ])
      ]),
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex align-items-center\"><a href=\"#\" class=\"symbol symbol-35px me-2\" data-bs-toggle=\"tooltip\" title=\"Ana Stone\"><img src=\"media/avatars/300-6.jpg\" alt=\"\"></a><a href=\"#\" class=\"symbol symbol-35px me-2\" data-bs-toggle=\"tooltip\" title=\"Mark Larson\"><img src=\"media/avatars/300-5.jpg\" alt=\"\"></a><a href=\"#\" class=\"symbol symbol-35px me-2\" data-bs-toggle=\"tooltip\" title=\"Sam Harris\"><img src=\"media/avatars/300-9.jpg\" alt=\"\"></a></div>", 1))
    ])
  ], 2))
}