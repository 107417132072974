import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-toolbar" }
const _hoisted_2 = { class: "svg-icon svg-icon-2" }
const _hoisted_3 = { class: "card-body p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([`bg-${_ctx.widgetColor}`, "card-header border-0 py-5"])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title fw-bolder text-white" }, "Sales Progress", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass([`btn-active-color-${_ctx.widgetColor}`, "btn btn-sm btn-icon btn-color-white btn-active-white border-0 me-n3"]),
          "data-kt-menu-trigger": "click",
          "data-kt-menu-placement": "bottom-end"
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ], 2),
        _createVNode(_component_Dropdown3)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_apexchart, {
        class: _normalizeClass([`bg-${_ctx.widgetColor}`, "mixed-widget-12-chart card-rounded-bottom"]),
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: "200",
        type: "bar"
      }, null, 8, ["class", "options", "series"]),
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"card-rounded bg-body mt-n10 position-relative card-px py-15\"><div class=\"row g-0 mb-7\"><div class=\"col mx-5\"><div class=\"fs-6 text-gray-400\">Avarage Sale</div><div class=\"fs-2 fw-bolder text-gray-800\">$650</div></div><div class=\"col mx-5\"><div class=\"fs-6 text-gray-400\">Comissions</div><div class=\"fs-2 fw-bolder text-gray-800\">$29,500</div></div></div><div class=\"row g-0\"><div class=\"col mx-5\"><div class=\"fs-6 text-gray-400\">Revenue</div><div class=\"fs-2 fw-bolder text-gray-800\">$55,000</div></div><div class=\"col mx-5\"><div class=\"fs-6 text-gray-400\">Expenses</div><div class=\"fs-2 fw-bolder text-gray-800\">$1,130,600</div></div></div></div>", 1))
    ])
  ], 2))
}