import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column" }
const _hoisted_2 = { class: "d-flex flex-column flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"]),
    style: _normalizeStyle(`background-color: ${_ctx.widgetColor}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("a", {
          href: "#",
          class: "text-dark text-hover-primary fw-bolder fs-3"
        }, "Earnings", -1)),
        _createVNode(_component_apexchart, {
          class: "mixed-widget-13-chart",
          options: _ctx.chartOptions,
          series: _ctx.series,
          height: _ctx.chartHeight,
          type: "area"
        }, null, 8, ["options", "series", "height"])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "pt-5" }, [
        _createElementVNode("span", { class: "text-dark fw-bolder fs-2x lh-0" }, "$"),
        _createElementVNode("span", { class: "text-dark fw-bolder fs-3x me-2 lh-0" }, "560"),
        _createElementVNode("span", { class: "text-dark fw-bolder fs-6 lh-0" }, "+ 28% this week")
      ], -1))
    ])
  ], 6))
}