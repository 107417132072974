import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 py-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body d-flex flex-column" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "pt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Action Needed"),
        _createElementVNode("span", { class: "text-muted fw-bold fs-7" }, "Complete your profile setup")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_apexchart, {
          class: "mixed-widget-4-chart",
          options: _ctx.chartOptions,
          series: _ctx.series,
          height: _ctx.chartHeight,
          type: "radialBar"
        }, null, 8, ["options", "series", "height"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-center fs-6 pb-5" }, [
          _createElementVNode("span", { class: "badge badge-light-danger fs-8" }, "Notes:"),
          _createTextVNode("  Current sprint requires stakeholders"),
          _createElementVNode("br"),
          _createTextVNode(" to approve newly amended policies ")
        ], -1)),
        _createElementVNode("a", {
          href: "#",
          class: _normalizeClass([`btn-${_ctx.btnColor}`, "btn w-100 py-3"])
        }, "Take Action", 2)
      ])
    ])
  ], 2))
}