import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-body p-0" }
const _hoisted_2 = { class: "d-flex flex-stack" }
const _hoisted_3 = { class: "ms-1" }
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = {
  class: "shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white",
  style: {"margin-top":"-100px"}
}
const _hoisted_6 = { class: "symbol symbol-45px w-40px me-5" }
const _hoisted_7 = { class: "symbol-label bg-lighten" }
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = { class: "d-flex align-items-center flex-wrap w-100" }
const _hoisted_10 = { class: "mb-1 pe-3 flex-grow-1" }
const _hoisted_11 = {
  href: "#",
  class: "fs-5 text-gray-800 text-hover-primary fw-bolder"
}
const _hoisted_12 = { class: "text-gray-400 fw-bold fs-7" }
const _hoisted_13 = { class: "d-flex align-items-center" }
const _hoisted_14 = { class: "fw-bolder fs-5 text-gray-800 pe-1" }
const _hoisted_15 = {
  key: 0,
  class: "svg-icon svg-icon-5 svg-icon-success ms-1"
}
const _hoisted_16 = {
  key: 1,
  class: "svg-icon svg-icon-5 svg-icon-danger ms-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([`bg-${_ctx.widgetColor}`, "px-9 pt-7 card-rounded h-275px w-100"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "m-0 text-white fw-bolder fs-3" }, "Sales Summary", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass([`btn-active-color-${_ctx.widgetColor}`, "btn btn-sm btn-icon btn-color-white btn-active-white border-0 me-n3"]),
              "data-kt-menu-trigger": "click",
              "data-kt-menu-placement": "bottom-end",
              "data-kt-menu-flip": "top-end"
            }, [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
              ])
            ], 2),
            _createVNode(_component_Dropdown3)
          ])
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex text-center flex-column text-white pt-8" }, [
          _createElementVNode("span", { class: "fw-bold fs-7" }, "You Balance"),
          _createElementVNode("span", { class: "fw-bolder fs-2x pt-1" }, "$37,562.00")
        ], -1))
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass([[index !== _ctx.items.length && 'mb-7'], "d-flex align-items-center"])
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_inline_svg, {
                    src: item.icon
                  }, null, 8, ["src"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("a", _hoisted_11, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(item.description), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(item.stats), 1),
                (item.arrow === 'up')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr066.svg" })
                    ]))
                  : (item.arrow === 'down')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr065.svg" })
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ], 2))
        }), 128))
      ])
    ])
  ], 2))
}