import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "d-flex flex-stack" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "symbol symbol-60px me-5" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3" }
const _hoisted_7 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-5"
}
const _hoisted_8 = { class: "text-muted fw-bold" }
const _hoisted_9 = { class: "ms-1" }
const _hoisted_10 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = { class: "d-flex flex-column w-100 mt-12" }
const _hoisted_13 = { class: "progress h-5px w-100" }
const _hoisted_14 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass([`bg-${_ctx.color}-light`, "symbol-label"])
            }, [
              _createElementVNode("img", {
                src: _ctx.image,
                class: "h-50 align-self-center",
                alt: ""
              }, null, 8, _hoisted_5)
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.title), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.date), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
            ])
          ]),
          _createVNode(_component_Dropdown2)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-dark me-2 fw-bolder pb-3" }, " Progress ", -1)),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", {
            class: _normalizeClass([`bg-${_ctx.color}`, "progress-bar"]),
            role: "progressbar",
            style: _normalizeStyle(`width: ${_ctx.progress}%`),
            "aria-valuenow": _ctx.progress,
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }, null, 14, _hoisted_14)
        ])
      ]),
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"d-flex flex-column mt-10\"><div class=\"text-dark me-2 fw-bolder pb-4\">Team</div><div class=\"d-flex\"><a href=\"#\" class=\"symbol symbol-35px me-2\" data-bs-toggle=\"tooltip\" title=\"Ana Stone\"><img src=\"media/avatars/300-6.jpg\" alt=\"\"></a><a href=\"#\" class=\"symbol symbol-35px me-2\" data-bs-toggle=\"tooltip\" title=\"Mark Larson\"><img src=\"media/avatars/300-5.jpg\" alt=\"\"></a><a href=\"#\" class=\"symbol symbol-35px me-2\" data-bs-toggle=\"tooltip\" title=\"Sam Harris\"><img src=\"media/avatars/300-9.jpg\" alt=\"\"></a><a href=\"#\" class=\"symbol symbol-35px\" data-bs-toggle=\"tooltip\" title=\"Alice Micto\"><img src=\"media/avatars/300-10.jpg\" alt=\"\"></a></div></div>", 1))
    ])
  ], 2))
}