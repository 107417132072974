import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column" }
const _hoisted_2 = { class: "row g-0" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "d-flex align-items-center mb-9 me-2" }
const _hoisted_5 = { class: "symbol symbol-40px me-3" }
const _hoisted_6 = { class: "symbol-label bg-white bg-opacity-50" }
const _hoisted_7 = { class: "svg-icon svg-icon-1 svg-icon-dark" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "d-flex align-items-center mb-9 ms-2" }
const _hoisted_10 = { class: "symbol symbol-40px me-3" }
const _hoisted_11 = { class: "symbol-label bg-white bg-opacity-50" }
const _hoisted_12 = { class: "svg-icon svg-icon-1 svg-icon-dark" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "d-flex align-items-center me-2" }
const _hoisted_15 = { class: "symbol symbol-40px me-3" }
const _hoisted_16 = { class: "symbol-label bg-white bg-opacity-50" }
const _hoisted_17 = { class: "svg-icon svg-icon-1 svg-icon-dark" }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "d-flex align-items-center ms-2" }
const _hoisted_20 = { class: "symbol symbol-40px me-3" }
const _hoisted_21 = { class: "symbol-label bg-white bg-opacity-50" }
const _hoisted_22 = { class: "svg-icon svg-icon-1 svg-icon-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"]),
    style: _normalizeStyle(`background-color: ${_ctx.widgetColor}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "d-flex flex-column mb-7" }, [
        _createElementVNode("a", {
          href: "#",
          class: "text-dark text-hover-primary fw-bolder fs-3"
        }, "Summary")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs043.svg" })
                ])
              ])
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", null, [
              _createElementVNode("div", { class: "fs-5 text-dark fw-bolder lh-1" }, "$50K"),
              _createElementVNode("div", { class: "fs-7 text-gray-600 fw-bold" }, "Sales")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs046.svg" })
                ])
              ])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, [
              _createElementVNode("div", { class: "fs-5 text-dark fw-bolder lh-1" }, "$4,5K"),
              _createElementVNode("div", { class: "fs-7 text-gray-600 fw-bold" }, "Revenue")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("span", _hoisted_17, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs022.svg" })
                ])
              ])
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", null, [
              _createElementVNode("div", { class: "fs-5 text-dark fw-bolder lh-1" }, "40"),
              _createElementVNode("div", { class: "fs-7 text-gray-600 fw-bold" }, "Tasks")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("span", _hoisted_22, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs045.svg" })
                ])
              ])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", null, [
              _createElementVNode("div", { class: "fs-5 text-dark fw-bolder lh-1" }, "$5.8M"),
              _createElementVNode("div", { class: "fs-7 text-gray-600 fw-bold" }, "Sales")
            ], -1))
          ])
        ])
      ])
    ])
  ], 6))
}